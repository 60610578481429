<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="ID"
                  placeholder="NewsLetter Id"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.id"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="filter"
                >
                  <CIcon name="cil-check-circle" />
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="reset"
                  size="sm"
                  color="danger"
                  @click="clearFilters"
                >
                  <CIcon name="cil-ban" />
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                  label="Create Time"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.createTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Name"
                  placeholder="NewsLetter Name"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.name"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CRow>
                  <CCol sm="3"><label>NewsLetter Status</label></CCol>
                  <CCol sm="9">
                    <multiselect
                      placeholder="NewsLetter durumunu seç"
                      horizontal
                      size="sm"
                      v-model="data.newsLetterStatus"
                      :options="comboNewsLetterStatus"
                      :multiple="true"
                      label="label"
                      track-by="label"
                      select-label="Seçiniz"
                      selected-label="Seçildi"
                      deselect-label="Kaldır"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CRow>
                  <CCol sm="3"><label>Target SubTypes</label></CCol>
                  <CCol sm="9">
                    <multiselect
                      placeholder="NewsLetter hedef üye seç"
                      horizontal
                      size="sm"
                      v-model="data.targetSubstypes"
                      :options="comboTargetSubTypes"
                      :multiple="true"
                      label="label"
                      track-by="label"
                      select-label="Seçiniz"
                      selected-label="Seçildi"
                      deselect-label="Kaldır"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow> </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Only Interested Subs"
                  placeholder="Only Interested Subs"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.onlyInterestedSubs"
                >
                </CSelect>
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
  import Multiselect from 'vue-multiselect'
   export default{
       name: "NewsletterFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               comboDataList: [
                { label: 'Hepsi', value: '' },
                { label: 'Evet', value: 'true' },
                { label: 'Hayır', value: 'false' }
              ],
              comboTargetSubTypes: [
                { label: 'NewsLetter', value: '1' },
                { label: 'ProductSale', value: '2' },
                { label: 'ProductReprint', value: '3' },
                { label: 'Test', value: '4' },
                { label: 'Promotion', value: '5' }
              ],
              comboNewsLetterStatus: [
                { label: 'ready', value: 'ready' },
                { label: 'sending', value: 'sending' },
                { label: 'finished', value: 'finished' },
                { label: 'stop', value: 'stop' }
              ],
           }
       },
       components:{Multiselect},
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>